<template>
  <div id="Manage">
    <!-- 基础设置 卡券管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加银行网点</el-button>

          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchUSer(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
            <div class="line">—</div>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @changeSwitch='changeSwitch'
                     @getPage="getPage">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickDeleteData(scope.$index, scope.row)">删除</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getweiaiBankPoint, metweiaiBankPoint, changeweiaiUserStatus } from '../../../api/weiaiApi'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      areaName: [],
      areaID: [],
      searchData: '',//存放搜索的数据，分页时使用
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增银行网点',
        editName: '编辑银行网点',
        isShowDialogVisible: false
      },

      // form表单 基础数据
      openForm: {
        bank_id: '',
        name: '',
        address: '',
        status: '',
      },
      openFormLabel: [
        {
          model: 'bank_id',
          label: '银行ID',
        },
        {
          model: 'name',
          label: '网点名称',
        },
        {
          model: 'address',
          label: '网点地址',
        },
        {
          model: 'status',
          label: '状态',
          type: 'select',
          options: [
            { value: 0, label: '禁用' },
            { value: 1, label: '启用' },
          ],
        },
      ],

      // form表单 搜索数据
      searchForm: {
        bank_id: '',
        name: '',
        address: '',
        status: '',
      },
      searchformLabel: [
        {
          model: 'bank_id',
          label: '银行id',
        },
        {
          model: 'name',
          label: '网点名称',
        },
        {
          model: 'address',
          label: '网点地址',
        },
        {
          model: 'status',
          label: '状态',
          type: 'select',
          options: [
            { value: 0, label: '禁用' },
            { value: 1, label: '启用' },
          ],
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: 'ID',
        },
        {
          prop: 'bank_id',
          label: '银行网点ID',
        },
        {
          prop: 'name',
          label: '网点名称'
        },
        {
          prop: 'tel',
          label: '电话'
        },
        {
          prop: 'address',
          label: '地址'
        },
        {
          prop: 'status',
          label: '状态',
          type: 'state'
        },
        {
          prop: 'create_time',
          label: '创建时间'
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },


      // 用户-全部-数据
      userAlldataObj: {},

    }
  },

  mounted () {
    // this.getCardList();
    this.getweiaiBankPoint()
  },
  beforeUpdate () {
  },
  watch: {
    userAlldataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.userAlldataObj.list,
        {
        },
      )
    }
  },

  methods: {

    //获得下列分类页的页数
    getPage () {
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getweiaiBankPoint(this.searchData)
      } else {
        this.getweiaiBankPoint()
      }
    },
    // 获取--银行网点列表数据
    getweiaiBankPoint (data) {
      if (data) {
        getweiaiBankPoint(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.userAlldataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getweiaiBankPoint({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.userAlldataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }

    },

    //编辑或添加银行网点
    metweiaiBankPoint (met, data) {
      return new Promise(resolve => {
        metweiaiBankPoint(met, data).then(res => {
          console.log(res)
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })

    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 确定提交数据操作
    async clickSubmitData (type, data) {
      console.log(data)

      if (type == 'edit') {
        const DATA = {
          id: data.id,
          bank_id: data.bank_id,
          name: data.name,
          address: data.address,
          status: data.status,
        }
        let res = await this.metweiaiBankPoint('PUT', DATA)
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.getweiaiBankPoint()
          this.updataTableData()
          this.dialogFonfig.isShowDialogVisible = false
          this.openForm = this.$options.data().openForm;
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      } else if (type == 'add') {
        const DATA = {
          bank_id: data.bank_id,
          name: data.name,
          address: data.address,
          status: data.status,
        }
        let res = await this.metweiaiBankPoint('POST', DATA)
        console.log(res.code)
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.getweiaiBankPoint()
          this.updataTableData()
          this.dialogFonfig.isShowDialogVisible = false
          this.openForm = this.$options.data().openForm;
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      }
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },

    // 获取最大的 id值
    MaxID (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 添加数据
    clickAddData () {
      this.MaxId = this.MaxID(this.tableData, 'id')
      this.openForm.id = this.MaxId + 1
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'add'
    },
    // 编辑操作
    async clickEditData (idx, row) {
      this.openForm = {
        id: row.id,
        bank_id: row.bank_id,
        name: row.name,
        address: row.address,
        status: row.status,
      }
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    //删除网点
    async clickDeleteData (idx, row) {
      const DATA = {
        id: row.id,
      }
      let res = await this.metweiaiBankPoint('DELETE', DATA)
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success'
        })
        this.updataTableData()
      } else {
        this.$message({
          message: res.message,
          type: 'error'
        })
      }
    },
    //改变用户状态
    async changeSwitch (row) {
      console.log(row.id)
      if (row.status == 1) {
        row.status == 0
      } else {
        row.status == 1
      }
      const DATA = {
        id: row.id,
        status: row.status
      }
      let res = changeweiaiUserStatus(DATA).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      })
    },
    //刷新表格
    updataTableData () {
      this.getweiaiBankPoint()
    },
    //搜索用户
    searchUSer (searchForm) {
      const data = {
        bank_id: searchForm.bank_id,
        name: searchForm.name,
        address: searchForm.address,
        status: searchForm.status,
      }
      this.searchData = data
      this.getweiaiBankPoint(data)
    },
    //重置筛选条件
    resetFilter (searchForm) {
      searchForm.bank_id = '';
      searchForm.name = '';
      searchForm.address = ''
      searchForm.status = ''
      this.searchData = ""
      this.getweiaiBankPoint()
    },
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .filtrateInput {
    background: #f8f7f7;
    width: 69%;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
    position: relative;
    .line {
      position: absolute;
      top: -49px;
      left: 692px;
      color: #d8d8d8;
    }
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .el-dialog {
    width: 30%;
  }
}
</style>
