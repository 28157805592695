import { request } from './request'
import qs from 'qs'
// 外部应用 微爱
// ---------------------------------
// /------------------------用户管理-------------------/
// 用户管理 列表数据
export function getweiaiUserList (params) {
  return request({
    url: '/admin/com/zsm/user',
    method: 'GET',
    params
  })
}
// 编辑用户信息
export function metWeiaiUserList (datas) {
  return request({
    url: '/admin/com/zsm/user',
    method: 'PUT',
    data: qs.stringify(datas)
  })
}

// /------------------------银行网点管理-------------------/
// 银行网点管理 列表数据
export function getweiaiBankPoint (params) {
    return request({
      url: '/admin/com/zsm/bank_point',
      method: 'GET',
      params
    })
}

//银行网点管理操作
export function metweiaiBankPoint (method, datas) {
    return request({
      url: '/admin/com/zsm/bank_point',
      method,
      data: qs.stringify(datas)
    })
  }

  //银行网点更新状态
export function changeweiaiUserStatus (datas) {
    return request({
      url: '/admin/com/zsm/bank_point',
      method: 'PUT',
      data: qs.stringify(datas)
    })
  }
// // 用户管理 详情数据
// export function getVipUserDetail (params) {
//     return request({
//       url: '/admin/user/show',
//       method: 'GET',
//       params
//     })
//   }
// // 编辑用户信息
// export function metVipUserList (datas) {
//   return request({
//     url: '/admin/user',
//     method: 'PUT',
//     data: qs.stringify(datas)
//   })
// }

// //用户更新状态
// export function changeVipUserStatus (datas) {
//     return request({
//       url: '/admin/user/change-status',
//       method: 'PUT',
//       data: qs.stringify(datas)
//     })
//   }